<template>
  <div>
    <el-form
      ref="form"
      label-width="80px"
      class="view pa24"
      label-position="left"
    >
      <el-form-item label="商品分类">
        <p style="color: #51CDCB;">{{ detailsDate.goodsTypeName }}</p>
      </el-form-item>
      <hr
        style="
          background-color: #e4e4e4;
          border: none;
          height: 1px;
          margin: 30px 0;
        "
      />

      <el-form-item label="商品名称"
        ><p>{{ detailsDate.goodsName }}</p>
      </el-form-item>

      <el-form-item label="商品库存"> {{ detailsDate.stockNum }}</el-form-item>

      <el-form-item label="商品价格">
        <span style="color:red">￥{{ detailsDate.price }}</span>
        <span style="color:red">~</span>
        <span style="color:red">￥{{ detailsDate.maxPrice }}</span>
      </el-form-item>

      <el-form-item label="商品规格">
        <template v-for="(item, index) in detailsDate.goodSpecModelList">
          <div :key="index">
            <span>{{ item.specName }}:</span>
            <template v-for="(a, b) in item.goodSpecModelList">
              <span
                :key="b"
                style="padding:5px 10px;border: 1px solid rgb(81, 205, 203);border-radius:5px;margin-left:5px"
                >{{ a.specAttribute }} <i style="color:red">{{ (a.price/100).toFixed(2) }}元 </i>
              </span>
            </template>
          </div>
        </template>
      </el-form-item>

      <el-form-item label="商品图片">
        <template v-for="(item, index) in detailsDate.goodPhoto">
          <el-image
            :key="index"
            style="width: 150px; height: 150px;border-radius:5px;margin-right:10px"
            :src="item"
          ></el-image>
        </template>
      </el-form-item>
      <hr
        style="
          background-color: #e4e4e4;
          border: none;
          height: 1px;
          margin: 30px 0;
        "
      />
      <el-form-item label="商品详情">
        <quill-editor v-model="detailsDate.goodsDetails"
      /></el-form-item>

      <el-form-item label-width="0px">
        <el-button size="medium" style="margin-left:80px" @click="isShow = true">预览</el-button>
      </el-form-item>
    </el-form>
    <prevGoods :isShow="isShow"
                :prod_imgs="detailsDate.goodPhoto"
                :prod-size="detailsDate.goodSpecModelList"
                :edit_html="detailsDate.goodsDetails"
                @close="prodClose"
                ></prevGoods>
  </div>
</template>

<script>
// @ts-ignore
import prevGoods from './components/prevGoods'
export default {
  components:{
    prevGoods
  },
  data() {
    return {
      detailsDate: {},
      dialogImageUrl: "",
      dialogVisible: false,
      isShow:false,
    };
  },

  created() {
    this.detailsDate = JSON.parse(this.$route.query.details);
    this.detailsDate.goodPhoto = this.detailsDate.goodPhoto
      ? this.detailsDate.goodPhoto.split(",")
      : [];
    
    this.detailsDate.price = (this.detailsDate.price/100).toFixed(2)
    console.log(this.detailsDate)
  },

  methods: {
    prodClose(){
      this.isShow = false
    }
  },
};
</script>
